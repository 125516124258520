import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { BlogEntry } from "../../../components";
import { DesktopRightImage, PhoneImage } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`A mystic country with a versatile landscape. From flowy forest trails in the
south, over steep and rocky mountains at the west coast, beautiful islands
and fjords, to the barren landcape in the north`}</p>
    <br />
    <Link className="text-black" to="/destination/norway/bergen" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/bergen_3.jpg" mdxType="DesktopRightImage" />
        <h2>{`Bergen`}</h2>
        <h4>{`The rough west coast`}</h4>
        <p>{`Surrounded by seven mountains, the second biggest city of Norway is said to
be the entrance to the fjords - and to great trails!`}</p>
        <PhoneImage src="destinations/bergen_3.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/norway/hardangerfjord" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/hardangerfjord_18.jpg" mdxType="DesktopRightImage" />
        <h2>{`Hardangerfjord`}</h2>
        <h4>{`Great mountain biking above the fjords`}</h4>
        <p>{`In between the steep cliffs that drop into the Fjord, William and Klara discovered,
cleared, and built a great variety of mountain bike trails.`}</p>
        <PhoneImage src="destinations/hardangerfjord_18.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>
    <Link className="text-black" to="/destination/norway/sandane" mdxType="Link">
      <BlogEntry mdxType="BlogEntry">
        <DesktopRightImage src="destinations/sandane_7.jpg" mdxType="DesktopRightImage" />
        <h2>{`Sandane`}</h2>
        <h4>{`The best trails of Nordfjord`}</h4>
        <p>{`The sun sets behind the mountains on the other end of the Fjord. We are flying down
the flowy trails above the tree line. The trail feels as it grew out of the landscape.
Like it would already exist for thousands of years.`}</p>
        <PhoneImage src="destinations/sandane_7.jpg" mdxType="PhoneImage" />
      </BlogEntry>
    </Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      